/**
 * @fileoverview gRPC-Web generated client stub for transporter
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: protobuf/transporter/transporter.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as protobuf_model_merged_pb from '../../protobuf/model/merged_pb';
import * as protobuf_transporter_transporter_pb from '../../protobuf/transporter/transporter_pb';


export class TransporterServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreate = new grpcWeb.MethodDescriptor(
    '/transporter.TransporterService/Create',
    grpcWeb.MethodType.UNARY,
    protobuf_transporter_transporter_pb.CreateTransporterRequest,
    protobuf_model_merged_pb.TransporterResponse,
    (request: protobuf_transporter_transporter_pb.CreateTransporterRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.TransporterResponse.deserializeBinary
  );

  create(
    request: protobuf_transporter_transporter_pb.CreateTransporterRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.TransporterResponse>;

  create(
    request: protobuf_transporter_transporter_pb.CreateTransporterRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.TransporterResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.TransporterResponse>;

  create(
    request: protobuf_transporter_transporter_pb.CreateTransporterRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.TransporterResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/transporter.TransporterService/Create',
        request,
        metadata || {},
        this.methodDescriptorCreate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/transporter.TransporterService/Create',
    request,
    metadata || {},
    this.methodDescriptorCreate);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/transporter.TransporterService/Update',
    grpcWeb.MethodType.UNARY,
    protobuf_transporter_transporter_pb.UpdateTransporterRequest,
    protobuf_model_merged_pb.TransporterResponse,
    (request: protobuf_transporter_transporter_pb.UpdateTransporterRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.TransporterResponse.deserializeBinary
  );

  update(
    request: protobuf_transporter_transporter_pb.UpdateTransporterRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.TransporterResponse>;

  update(
    request: protobuf_transporter_transporter_pb.UpdateTransporterRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.TransporterResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.TransporterResponse>;

  update(
    request: protobuf_transporter_transporter_pb.UpdateTransporterRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.TransporterResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/transporter.TransporterService/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/transporter.TransporterService/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorGet = new grpcWeb.MethodDescriptor(
    '/transporter.TransporterService/Get',
    grpcWeb.MethodType.UNARY,
    protobuf_transporter_transporter_pb.GetTransportersRequest,
    protobuf_transporter_transporter_pb.GetTransportersResponse,
    (request: protobuf_transporter_transporter_pb.GetTransportersRequest) => {
      return request.serializeBinary();
    },
    protobuf_transporter_transporter_pb.GetTransportersResponse.deserializeBinary
  );

  get(
    request: protobuf_transporter_transporter_pb.GetTransportersRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_transporter_transporter_pb.GetTransportersResponse>;

  get(
    request: protobuf_transporter_transporter_pb.GetTransportersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_transporter_transporter_pb.GetTransportersResponse) => void): grpcWeb.ClientReadableStream<protobuf_transporter_transporter_pb.GetTransportersResponse>;

  get(
    request: protobuf_transporter_transporter_pb.GetTransportersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_transporter_transporter_pb.GetTransportersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/transporter.TransporterService/Get',
        request,
        metadata || {},
        this.methodDescriptorGet,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/transporter.TransporterService/Get',
    request,
    metadata || {},
    this.methodDescriptorGet);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/transporter.TransporterService/Delete',
    grpcWeb.MethodType.UNARY,
    protobuf_transporter_transporter_pb.DeleteTransportersRequest,
    protobuf_transporter_transporter_pb.DeleteTransportersResponse,
    (request: protobuf_transporter_transporter_pb.DeleteTransportersRequest) => {
      return request.serializeBinary();
    },
    protobuf_transporter_transporter_pb.DeleteTransportersResponse.deserializeBinary
  );

  delete(
    request: protobuf_transporter_transporter_pb.DeleteTransportersRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_transporter_transporter_pb.DeleteTransportersResponse>;

  delete(
    request: protobuf_transporter_transporter_pb.DeleteTransportersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_transporter_transporter_pb.DeleteTransportersResponse) => void): grpcWeb.ClientReadableStream<protobuf_transporter_transporter_pb.DeleteTransportersResponse>;

  delete(
    request: protobuf_transporter_transporter_pb.DeleteTransportersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_transporter_transporter_pb.DeleteTransportersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/transporter.TransporterService/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/transporter.TransporterService/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorList = new grpcWeb.MethodDescriptor(
    '/transporter.TransporterService/List',
    grpcWeb.MethodType.UNARY,
    protobuf_transporter_transporter_pb.ListTransportersRequest,
    protobuf_transporter_transporter_pb.ListTransportersResponse,
    (request: protobuf_transporter_transporter_pb.ListTransportersRequest) => {
      return request.serializeBinary();
    },
    protobuf_transporter_transporter_pb.ListTransportersResponse.deserializeBinary
  );

  list(
    request: protobuf_transporter_transporter_pb.ListTransportersRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_transporter_transporter_pb.ListTransportersResponse>;

  list(
    request: protobuf_transporter_transporter_pb.ListTransportersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_transporter_transporter_pb.ListTransportersResponse) => void): grpcWeb.ClientReadableStream<protobuf_transporter_transporter_pb.ListTransportersResponse>;

  list(
    request: protobuf_transporter_transporter_pb.ListTransportersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_transporter_transporter_pb.ListTransportersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/transporter.TransporterService/List',
        request,
        metadata || {},
        this.methodDescriptorList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/transporter.TransporterService/List',
    request,
    metadata || {},
    this.methodDescriptorList);
  }

  methodDescriptorExport = new grpcWeb.MethodDescriptor(
    '/transporter.TransporterService/Export',
    grpcWeb.MethodType.UNARY,
    protobuf_transporter_transporter_pb.ExportTransportersRequest,
    protobuf_transporter_transporter_pb.ExportTransportersResponse,
    (request: protobuf_transporter_transporter_pb.ExportTransportersRequest) => {
      return request.serializeBinary();
    },
    protobuf_transporter_transporter_pb.ExportTransportersResponse.deserializeBinary
  );

  export(
    request: protobuf_transporter_transporter_pb.ExportTransportersRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_transporter_transporter_pb.ExportTransportersResponse>;

  export(
    request: protobuf_transporter_transporter_pb.ExportTransportersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_transporter_transporter_pb.ExportTransportersResponse) => void): grpcWeb.ClientReadableStream<protobuf_transporter_transporter_pb.ExportTransportersResponse>;

  export(
    request: protobuf_transporter_transporter_pb.ExportTransportersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_transporter_transporter_pb.ExportTransportersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/transporter.TransporterService/Export',
        request,
        metadata || {},
        this.methodDescriptorExport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/transporter.TransporterService/Export',
    request,
    metadata || {},
    this.methodDescriptorExport);
  }

}

