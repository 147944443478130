/**
 * @fileoverview gRPC-Web generated client stub for transporter_direction
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: protobuf/transporter_direction/transporter_direction.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as protobuf_model_merged_pb from '../../protobuf/model/merged_pb';
import * as protobuf_transporter_direction_transporter_direction_pb from '../../protobuf/transporter_direction/transporter_direction_pb';


export class TransporterDirectionServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreate = new grpcWeb.MethodDescriptor(
    '/transporter_direction.TransporterDirectionService/Create',
    grpcWeb.MethodType.UNARY,
    protobuf_transporter_direction_transporter_direction_pb.CreateTransporterDirectionRequest,
    protobuf_model_merged_pb.TransporterDirectionResponse,
    (request: protobuf_transporter_direction_transporter_direction_pb.CreateTransporterDirectionRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.TransporterDirectionResponse.deserializeBinary
  );

  create(
    request: protobuf_transporter_direction_transporter_direction_pb.CreateTransporterDirectionRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.TransporterDirectionResponse>;

  create(
    request: protobuf_transporter_direction_transporter_direction_pb.CreateTransporterDirectionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.TransporterDirectionResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.TransporterDirectionResponse>;

  create(
    request: protobuf_transporter_direction_transporter_direction_pb.CreateTransporterDirectionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.TransporterDirectionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/transporter_direction.TransporterDirectionService/Create',
        request,
        metadata || {},
        this.methodDescriptorCreate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/transporter_direction.TransporterDirectionService/Create',
    request,
    metadata || {},
    this.methodDescriptorCreate);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/transporter_direction.TransporterDirectionService/Update',
    grpcWeb.MethodType.UNARY,
    protobuf_transporter_direction_transporter_direction_pb.UpdateTransporterDirectionRequest,
    protobuf_model_merged_pb.TransporterDirectionResponse,
    (request: protobuf_transporter_direction_transporter_direction_pb.UpdateTransporterDirectionRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.TransporterDirectionResponse.deserializeBinary
  );

  update(
    request: protobuf_transporter_direction_transporter_direction_pb.UpdateTransporterDirectionRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.TransporterDirectionResponse>;

  update(
    request: protobuf_transporter_direction_transporter_direction_pb.UpdateTransporterDirectionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.TransporterDirectionResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.TransporterDirectionResponse>;

  update(
    request: protobuf_transporter_direction_transporter_direction_pb.UpdateTransporterDirectionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.TransporterDirectionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/transporter_direction.TransporterDirectionService/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/transporter_direction.TransporterDirectionService/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorGet = new grpcWeb.MethodDescriptor(
    '/transporter_direction.TransporterDirectionService/Get',
    grpcWeb.MethodType.UNARY,
    protobuf_transporter_direction_transporter_direction_pb.GetTransporterDirectionsRequest,
    protobuf_transporter_direction_transporter_direction_pb.GetTransporterDirectionsResponse,
    (request: protobuf_transporter_direction_transporter_direction_pb.GetTransporterDirectionsRequest) => {
      return request.serializeBinary();
    },
    protobuf_transporter_direction_transporter_direction_pb.GetTransporterDirectionsResponse.deserializeBinary
  );

  get(
    request: protobuf_transporter_direction_transporter_direction_pb.GetTransporterDirectionsRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_transporter_direction_transporter_direction_pb.GetTransporterDirectionsResponse>;

  get(
    request: protobuf_transporter_direction_transporter_direction_pb.GetTransporterDirectionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_transporter_direction_transporter_direction_pb.GetTransporterDirectionsResponse) => void): grpcWeb.ClientReadableStream<protobuf_transporter_direction_transporter_direction_pb.GetTransporterDirectionsResponse>;

  get(
    request: protobuf_transporter_direction_transporter_direction_pb.GetTransporterDirectionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_transporter_direction_transporter_direction_pb.GetTransporterDirectionsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/transporter_direction.TransporterDirectionService/Get',
        request,
        metadata || {},
        this.methodDescriptorGet,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/transporter_direction.TransporterDirectionService/Get',
    request,
    metadata || {},
    this.methodDescriptorGet);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/transporter_direction.TransporterDirectionService/Delete',
    grpcWeb.MethodType.UNARY,
    protobuf_transporter_direction_transporter_direction_pb.DeleteTransporterDirectionsRequest,
    protobuf_transporter_direction_transporter_direction_pb.DeleteTransporterDirectionsResponse,
    (request: protobuf_transporter_direction_transporter_direction_pb.DeleteTransporterDirectionsRequest) => {
      return request.serializeBinary();
    },
    protobuf_transporter_direction_transporter_direction_pb.DeleteTransporterDirectionsResponse.deserializeBinary
  );

  delete(
    request: protobuf_transporter_direction_transporter_direction_pb.DeleteTransporterDirectionsRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_transporter_direction_transporter_direction_pb.DeleteTransporterDirectionsResponse>;

  delete(
    request: protobuf_transporter_direction_transporter_direction_pb.DeleteTransporterDirectionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_transporter_direction_transporter_direction_pb.DeleteTransporterDirectionsResponse) => void): grpcWeb.ClientReadableStream<protobuf_transporter_direction_transporter_direction_pb.DeleteTransporterDirectionsResponse>;

  delete(
    request: protobuf_transporter_direction_transporter_direction_pb.DeleteTransporterDirectionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_transporter_direction_transporter_direction_pb.DeleteTransporterDirectionsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/transporter_direction.TransporterDirectionService/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/transporter_direction.TransporterDirectionService/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorList = new grpcWeb.MethodDescriptor(
    '/transporter_direction.TransporterDirectionService/List',
    grpcWeb.MethodType.UNARY,
    protobuf_transporter_direction_transporter_direction_pb.ListTransporterDirectionsRequest,
    protobuf_transporter_direction_transporter_direction_pb.ListTransporterDirectionsResponse,
    (request: protobuf_transporter_direction_transporter_direction_pb.ListTransporterDirectionsRequest) => {
      return request.serializeBinary();
    },
    protobuf_transporter_direction_transporter_direction_pb.ListTransporterDirectionsResponse.deserializeBinary
  );

  list(
    request: protobuf_transporter_direction_transporter_direction_pb.ListTransporterDirectionsRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_transporter_direction_transporter_direction_pb.ListTransporterDirectionsResponse>;

  list(
    request: protobuf_transporter_direction_transporter_direction_pb.ListTransporterDirectionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_transporter_direction_transporter_direction_pb.ListTransporterDirectionsResponse) => void): grpcWeb.ClientReadableStream<protobuf_transporter_direction_transporter_direction_pb.ListTransporterDirectionsResponse>;

  list(
    request: protobuf_transporter_direction_transporter_direction_pb.ListTransporterDirectionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_transporter_direction_transporter_direction_pb.ListTransporterDirectionsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/transporter_direction.TransporterDirectionService/List',
        request,
        metadata || {},
        this.methodDescriptorList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/transporter_direction.TransporterDirectionService/List',
    request,
    metadata || {},
    this.methodDescriptorList);
  }

  methodDescriptorExport = new grpcWeb.MethodDescriptor(
    '/transporter_direction.TransporterDirectionService/Export',
    grpcWeb.MethodType.UNARY,
    protobuf_transporter_direction_transporter_direction_pb.ExportTransporterDirectionsRequest,
    protobuf_transporter_direction_transporter_direction_pb.ExportTransporterDirectionsResponse,
    (request: protobuf_transporter_direction_transporter_direction_pb.ExportTransporterDirectionsRequest) => {
      return request.serializeBinary();
    },
    protobuf_transporter_direction_transporter_direction_pb.ExportTransporterDirectionsResponse.deserializeBinary
  );

  export(
    request: protobuf_transporter_direction_transporter_direction_pb.ExportTransporterDirectionsRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_transporter_direction_transporter_direction_pb.ExportTransporterDirectionsResponse>;

  export(
    request: protobuf_transporter_direction_transporter_direction_pb.ExportTransporterDirectionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_transporter_direction_transporter_direction_pb.ExportTransporterDirectionsResponse) => void): grpcWeb.ClientReadableStream<protobuf_transporter_direction_transporter_direction_pb.ExportTransporterDirectionsResponse>;

  export(
    request: protobuf_transporter_direction_transporter_direction_pb.ExportTransporterDirectionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_transporter_direction_transporter_direction_pb.ExportTransporterDirectionsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/transporter_direction.TransporterDirectionService/Export',
        request,
        metadata || {},
        this.methodDescriptorExport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/transporter_direction.TransporterDirectionService/Export',
    request,
    metadata || {},
    this.methodDescriptorExport);
  }

}

