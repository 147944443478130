// source: protobuf/transporter_driver/transporter_driver.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var protobuf_validate_validate_pb = require('../../protobuf/validate/validate_pb.js');
goog.object.extend(proto, protobuf_validate_validate_pb);
var protobuf_filter_counters_pb = require('../../protobuf/filter/counters_pb.js');
goog.object.extend(proto, protobuf_filter_counters_pb);
var protobuf_filter_export_pb = require('../../protobuf/filter/export_pb.js');
goog.object.extend(proto, protobuf_filter_export_pb);
var protobuf_filter_request_pb = require('../../protobuf/filter/request_pb.js');
goog.object.extend(proto, protobuf_filter_request_pb);
var protobuf_model_merged_pb = require('../../protobuf/model/merged_pb.js');
goog.object.extend(proto, protobuf_model_merged_pb);
goog.exportSymbol('proto.transporter_driver.CreateTransporterDriverRequest', null, global);
goog.exportSymbol('proto.transporter_driver.DeleteTransporterDriversRequest', null, global);
goog.exportSymbol('proto.transporter_driver.DeleteTransporterDriversResponse', null, global);
goog.exportSymbol('proto.transporter_driver.ExportTransporterDriversRequest', null, global);
goog.exportSymbol('proto.transporter_driver.ExportTransporterDriversResponse', null, global);
goog.exportSymbol('proto.transporter_driver.GetTransporterDriversRequest', null, global);
goog.exportSymbol('proto.transporter_driver.GetTransporterDriversResponse', null, global);
goog.exportSymbol('proto.transporter_driver.ListTransporterDriversRequest', null, global);
goog.exportSymbol('proto.transporter_driver.ListTransporterDriversResponse', null, global);
goog.exportSymbol('proto.transporter_driver.UpdateTransporterDriverRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transporter_driver.DeleteTransporterDriversRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.transporter_driver.DeleteTransporterDriversRequest.repeatedFields_, null);
};
goog.inherits(proto.transporter_driver.DeleteTransporterDriversRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transporter_driver.DeleteTransporterDriversRequest.displayName = 'proto.transporter_driver.DeleteTransporterDriversRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transporter_driver.DeleteTransporterDriversResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.transporter_driver.DeleteTransporterDriversResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transporter_driver.DeleteTransporterDriversResponse.displayName = 'proto.transporter_driver.DeleteTransporterDriversResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transporter_driver.CreateTransporterDriverRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.transporter_driver.CreateTransporterDriverRequest.repeatedFields_, null);
};
goog.inherits(proto.transporter_driver.CreateTransporterDriverRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transporter_driver.CreateTransporterDriverRequest.displayName = 'proto.transporter_driver.CreateTransporterDriverRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transporter_driver.UpdateTransporterDriverRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.transporter_driver.UpdateTransporterDriverRequest.repeatedFields_, null);
};
goog.inherits(proto.transporter_driver.UpdateTransporterDriverRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transporter_driver.UpdateTransporterDriverRequest.displayName = 'proto.transporter_driver.UpdateTransporterDriverRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transporter_driver.GetTransporterDriversRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.transporter_driver.GetTransporterDriversRequest.repeatedFields_, null);
};
goog.inherits(proto.transporter_driver.GetTransporterDriversRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transporter_driver.GetTransporterDriversRequest.displayName = 'proto.transporter_driver.GetTransporterDriversRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transporter_driver.GetTransporterDriversResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.transporter_driver.GetTransporterDriversResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transporter_driver.GetTransporterDriversResponse.displayName = 'proto.transporter_driver.GetTransporterDriversResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transporter_driver.ListTransporterDriversRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.transporter_driver.ListTransporterDriversRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transporter_driver.ListTransporterDriversRequest.displayName = 'proto.transporter_driver.ListTransporterDriversRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transporter_driver.ListTransporterDriversResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.transporter_driver.ListTransporterDriversResponse.repeatedFields_, null);
};
goog.inherits(proto.transporter_driver.ListTransporterDriversResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transporter_driver.ListTransporterDriversResponse.displayName = 'proto.transporter_driver.ListTransporterDriversResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transporter_driver.ExportTransporterDriversRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.transporter_driver.ExportTransporterDriversRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transporter_driver.ExportTransporterDriversRequest.displayName = 'proto.transporter_driver.ExportTransporterDriversRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transporter_driver.ExportTransporterDriversResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.transporter_driver.ExportTransporterDriversResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transporter_driver.ExportTransporterDriversResponse.displayName = 'proto.transporter_driver.ExportTransporterDriversResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.transporter_driver.DeleteTransporterDriversRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transporter_driver.DeleteTransporterDriversRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.transporter_driver.DeleteTransporterDriversRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transporter_driver.DeleteTransporterDriversRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transporter_driver.DeleteTransporterDriversRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transporter_driver.DeleteTransporterDriversRequest}
 */
proto.transporter_driver.DeleteTransporterDriversRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transporter_driver.DeleteTransporterDriversRequest;
  return proto.transporter_driver.DeleteTransporterDriversRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transporter_driver.DeleteTransporterDriversRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transporter_driver.DeleteTransporterDriversRequest}
 */
proto.transporter_driver.DeleteTransporterDriversRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transporter_driver.DeleteTransporterDriversRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transporter_driver.DeleteTransporterDriversRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transporter_driver.DeleteTransporterDriversRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transporter_driver.DeleteTransporterDriversRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.transporter_driver.DeleteTransporterDriversRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.transporter_driver.DeleteTransporterDriversRequest} returns this
 */
proto.transporter_driver.DeleteTransporterDriversRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.transporter_driver.DeleteTransporterDriversRequest} returns this
 */
proto.transporter_driver.DeleteTransporterDriversRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.transporter_driver.DeleteTransporterDriversRequest} returns this
 */
proto.transporter_driver.DeleteTransporterDriversRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transporter_driver.DeleteTransporterDriversResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.transporter_driver.DeleteTransporterDriversResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transporter_driver.DeleteTransporterDriversResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transporter_driver.DeleteTransporterDriversResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transporter_driver.DeleteTransporterDriversResponse}
 */
proto.transporter_driver.DeleteTransporterDriversResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transporter_driver.DeleteTransporterDriversResponse;
  return proto.transporter_driver.DeleteTransporterDriversResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transporter_driver.DeleteTransporterDriversResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transporter_driver.DeleteTransporterDriversResponse}
 */
proto.transporter_driver.DeleteTransporterDriversResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transporter_driver.DeleteTransporterDriversResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transporter_driver.DeleteTransporterDriversResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transporter_driver.DeleteTransporterDriversResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transporter_driver.DeleteTransporterDriversResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.transporter_driver.CreateTransporterDriverRequest.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transporter_driver.CreateTransporterDriverRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.transporter_driver.CreateTransporterDriverRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transporter_driver.CreateTransporterDriverRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transporter_driver.CreateTransporterDriverRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    transporterId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    phonesList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    comment: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transporter_driver.CreateTransporterDriverRequest}
 */
proto.transporter_driver.CreateTransporterDriverRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transporter_driver.CreateTransporterDriverRequest;
  return proto.transporter_driver.CreateTransporterDriverRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transporter_driver.CreateTransporterDriverRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transporter_driver.CreateTransporterDriverRequest}
 */
proto.transporter_driver.CreateTransporterDriverRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransporterId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addPhones(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transporter_driver.CreateTransporterDriverRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transporter_driver.CreateTransporterDriverRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transporter_driver.CreateTransporterDriverRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transporter_driver.CreateTransporterDriverRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransporterId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPhonesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string transporter_id = 1;
 * @return {string}
 */
proto.transporter_driver.CreateTransporterDriverRequest.prototype.getTransporterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.transporter_driver.CreateTransporterDriverRequest} returns this
 */
proto.transporter_driver.CreateTransporterDriverRequest.prototype.setTransporterId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string first_name = 5;
 * @return {string}
 */
proto.transporter_driver.CreateTransporterDriverRequest.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.transporter_driver.CreateTransporterDriverRequest} returns this
 */
proto.transporter_driver.CreateTransporterDriverRequest.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string last_name = 6;
 * @return {string}
 */
proto.transporter_driver.CreateTransporterDriverRequest.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.transporter_driver.CreateTransporterDriverRequest} returns this
 */
proto.transporter_driver.CreateTransporterDriverRequest.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated string phones = 8;
 * @return {!Array<string>}
 */
proto.transporter_driver.CreateTransporterDriverRequest.prototype.getPhonesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.transporter_driver.CreateTransporterDriverRequest} returns this
 */
proto.transporter_driver.CreateTransporterDriverRequest.prototype.setPhonesList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.transporter_driver.CreateTransporterDriverRequest} returns this
 */
proto.transporter_driver.CreateTransporterDriverRequest.prototype.addPhones = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.transporter_driver.CreateTransporterDriverRequest} returns this
 */
proto.transporter_driver.CreateTransporterDriverRequest.prototype.clearPhonesList = function() {
  return this.setPhonesList([]);
};


/**
 * optional string comment = 11;
 * @return {string}
 */
proto.transporter_driver.CreateTransporterDriverRequest.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.transporter_driver.CreateTransporterDriverRequest} returns this
 */
proto.transporter_driver.CreateTransporterDriverRequest.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.transporter_driver.UpdateTransporterDriverRequest.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transporter_driver.UpdateTransporterDriverRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.transporter_driver.UpdateTransporterDriverRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transporter_driver.UpdateTransporterDriverRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transporter_driver.UpdateTransporterDriverRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    transporterId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    phonesList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    comment: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transporter_driver.UpdateTransporterDriverRequest}
 */
proto.transporter_driver.UpdateTransporterDriverRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transporter_driver.UpdateTransporterDriverRequest;
  return proto.transporter_driver.UpdateTransporterDriverRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transporter_driver.UpdateTransporterDriverRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transporter_driver.UpdateTransporterDriverRequest}
 */
proto.transporter_driver.UpdateTransporterDriverRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransporterId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addPhones(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transporter_driver.UpdateTransporterDriverRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transporter_driver.UpdateTransporterDriverRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transporter_driver.UpdateTransporterDriverRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transporter_driver.UpdateTransporterDriverRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransporterId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPhonesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string transporter_id = 1;
 * @return {string}
 */
proto.transporter_driver.UpdateTransporterDriverRequest.prototype.getTransporterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.transporter_driver.UpdateTransporterDriverRequest} returns this
 */
proto.transporter_driver.UpdateTransporterDriverRequest.prototype.setTransporterId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.transporter_driver.UpdateTransporterDriverRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.transporter_driver.UpdateTransporterDriverRequest} returns this
 */
proto.transporter_driver.UpdateTransporterDriverRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string first_name = 5;
 * @return {string}
 */
proto.transporter_driver.UpdateTransporterDriverRequest.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.transporter_driver.UpdateTransporterDriverRequest} returns this
 */
proto.transporter_driver.UpdateTransporterDriverRequest.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string last_name = 6;
 * @return {string}
 */
proto.transporter_driver.UpdateTransporterDriverRequest.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.transporter_driver.UpdateTransporterDriverRequest} returns this
 */
proto.transporter_driver.UpdateTransporterDriverRequest.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated string phones = 8;
 * @return {!Array<string>}
 */
proto.transporter_driver.UpdateTransporterDriverRequest.prototype.getPhonesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.transporter_driver.UpdateTransporterDriverRequest} returns this
 */
proto.transporter_driver.UpdateTransporterDriverRequest.prototype.setPhonesList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.transporter_driver.UpdateTransporterDriverRequest} returns this
 */
proto.transporter_driver.UpdateTransporterDriverRequest.prototype.addPhones = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.transporter_driver.UpdateTransporterDriverRequest} returns this
 */
proto.transporter_driver.UpdateTransporterDriverRequest.prototype.clearPhonesList = function() {
  return this.setPhonesList([]);
};


/**
 * optional string comment = 11;
 * @return {string}
 */
proto.transporter_driver.UpdateTransporterDriverRequest.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.transporter_driver.UpdateTransporterDriverRequest} returns this
 */
proto.transporter_driver.UpdateTransporterDriverRequest.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.transporter_driver.GetTransporterDriversRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transporter_driver.GetTransporterDriversRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.transporter_driver.GetTransporterDriversRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transporter_driver.GetTransporterDriversRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transporter_driver.GetTransporterDriversRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transporter_driver.GetTransporterDriversRequest}
 */
proto.transporter_driver.GetTransporterDriversRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transporter_driver.GetTransporterDriversRequest;
  return proto.transporter_driver.GetTransporterDriversRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transporter_driver.GetTransporterDriversRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transporter_driver.GetTransporterDriversRequest}
 */
proto.transporter_driver.GetTransporterDriversRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transporter_driver.GetTransporterDriversRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transporter_driver.GetTransporterDriversRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transporter_driver.GetTransporterDriversRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transporter_driver.GetTransporterDriversRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.transporter_driver.GetTransporterDriversRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.transporter_driver.GetTransporterDriversRequest} returns this
 */
proto.transporter_driver.GetTransporterDriversRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.transporter_driver.GetTransporterDriversRequest} returns this
 */
proto.transporter_driver.GetTransporterDriversRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.transporter_driver.GetTransporterDriversRequest} returns this
 */
proto.transporter_driver.GetTransporterDriversRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transporter_driver.GetTransporterDriversResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.transporter_driver.GetTransporterDriversResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transporter_driver.GetTransporterDriversResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transporter_driver.GetTransporterDriversResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsMap: (f = msg.getItemsMap()) ? f.toObject(includeInstance, proto.model.TransporterDriverResponse.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transporter_driver.GetTransporterDriversResponse}
 */
proto.transporter_driver.GetTransporterDriversResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transporter_driver.GetTransporterDriversResponse;
  return proto.transporter_driver.GetTransporterDriversResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transporter_driver.GetTransporterDriversResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transporter_driver.GetTransporterDriversResponse}
 */
proto.transporter_driver.GetTransporterDriversResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getItemsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.model.TransporterDriverResponse.deserializeBinaryFromReader, "", new proto.model.TransporterDriverResponse());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transporter_driver.GetTransporterDriversResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transporter_driver.GetTransporterDriversResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transporter_driver.GetTransporterDriversResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transporter_driver.GetTransporterDriversResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.model.TransporterDriverResponse.serializeBinaryToWriter);
  }
};


/**
 * map<string, model.TransporterDriverResponse> items = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.model.TransporterDriverResponse>}
 */
proto.transporter_driver.GetTransporterDriversResponse.prototype.getItemsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.model.TransporterDriverResponse>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.model.TransporterDriverResponse));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.transporter_driver.GetTransporterDriversResponse} returns this
 */
proto.transporter_driver.GetTransporterDriversResponse.prototype.clearItemsMap = function() {
  this.getItemsMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transporter_driver.ListTransporterDriversRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.transporter_driver.ListTransporterDriversRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transporter_driver.ListTransporterDriversRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transporter_driver.ListTransporterDriversRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && protobuf_filter_request_pb.Request.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transporter_driver.ListTransporterDriversRequest}
 */
proto.transporter_driver.ListTransporterDriversRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transporter_driver.ListTransporterDriversRequest;
  return proto.transporter_driver.ListTransporterDriversRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transporter_driver.ListTransporterDriversRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transporter_driver.ListTransporterDriversRequest}
 */
proto.transporter_driver.ListTransporterDriversRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_filter_request_pb.Request;
      reader.readMessage(value,protobuf_filter_request_pb.Request.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transporter_driver.ListTransporterDriversRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transporter_driver.ListTransporterDriversRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transporter_driver.ListTransporterDriversRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transporter_driver.ListTransporterDriversRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_filter_request_pb.Request.serializeBinaryToWriter
    );
  }
};


/**
 * optional filter.Request filter = 1;
 * @return {?proto.filter.Request}
 */
proto.transporter_driver.ListTransporterDriversRequest.prototype.getFilter = function() {
  return /** @type{?proto.filter.Request} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_request_pb.Request, 1));
};


/**
 * @param {?proto.filter.Request|undefined} value
 * @return {!proto.transporter_driver.ListTransporterDriversRequest} returns this
*/
proto.transporter_driver.ListTransporterDriversRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.transporter_driver.ListTransporterDriversRequest} returns this
 */
proto.transporter_driver.ListTransporterDriversRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.transporter_driver.ListTransporterDriversRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.transporter_driver.ListTransporterDriversResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transporter_driver.ListTransporterDriversResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.transporter_driver.ListTransporterDriversResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transporter_driver.ListTransporterDriversResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transporter_driver.ListTransporterDriversResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    counters: (f = msg.getCounters()) && protobuf_filter_counters_pb.Counters.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    protobuf_model_merged_pb.TransporterDriverResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transporter_driver.ListTransporterDriversResponse}
 */
proto.transporter_driver.ListTransporterDriversResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transporter_driver.ListTransporterDriversResponse;
  return proto.transporter_driver.ListTransporterDriversResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transporter_driver.ListTransporterDriversResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transporter_driver.ListTransporterDriversResponse}
 */
proto.transporter_driver.ListTransporterDriversResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_filter_counters_pb.Counters;
      reader.readMessage(value,protobuf_filter_counters_pb.Counters.deserializeBinaryFromReader);
      msg.setCounters(value);
      break;
    case 2:
      var value = new protobuf_model_merged_pb.TransporterDriverResponse;
      reader.readMessage(value,protobuf_model_merged_pb.TransporterDriverResponse.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transporter_driver.ListTransporterDriversResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transporter_driver.ListTransporterDriversResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transporter_driver.ListTransporterDriversResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transporter_driver.ListTransporterDriversResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCounters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_filter_counters_pb.Counters.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      protobuf_model_merged_pb.TransporterDriverResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional filter.Counters counters = 1;
 * @return {?proto.filter.Counters}
 */
proto.transporter_driver.ListTransporterDriversResponse.prototype.getCounters = function() {
  return /** @type{?proto.filter.Counters} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_counters_pb.Counters, 1));
};


/**
 * @param {?proto.filter.Counters|undefined} value
 * @return {!proto.transporter_driver.ListTransporterDriversResponse} returns this
*/
proto.transporter_driver.ListTransporterDriversResponse.prototype.setCounters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.transporter_driver.ListTransporterDriversResponse} returns this
 */
proto.transporter_driver.ListTransporterDriversResponse.prototype.clearCounters = function() {
  return this.setCounters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.transporter_driver.ListTransporterDriversResponse.prototype.hasCounters = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated model.TransporterDriverResponse items = 2;
 * @return {!Array<!proto.model.TransporterDriverResponse>}
 */
proto.transporter_driver.ListTransporterDriversResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.model.TransporterDriverResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, protobuf_model_merged_pb.TransporterDriverResponse, 2));
};


/**
 * @param {!Array<!proto.model.TransporterDriverResponse>} value
 * @return {!proto.transporter_driver.ListTransporterDriversResponse} returns this
*/
proto.transporter_driver.ListTransporterDriversResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.model.TransporterDriverResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.TransporterDriverResponse}
 */
proto.transporter_driver.ListTransporterDriversResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.model.TransporterDriverResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.transporter_driver.ListTransporterDriversResponse} returns this
 */
proto.transporter_driver.ListTransporterDriversResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transporter_driver.ExportTransporterDriversRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.transporter_driver.ExportTransporterDriversRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transporter_driver.ExportTransporterDriversRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transporter_driver.ExportTransporterDriversRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && protobuf_filter_request_pb.Request.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transporter_driver.ExportTransporterDriversRequest}
 */
proto.transporter_driver.ExportTransporterDriversRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transporter_driver.ExportTransporterDriversRequest;
  return proto.transporter_driver.ExportTransporterDriversRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transporter_driver.ExportTransporterDriversRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transporter_driver.ExportTransporterDriversRequest}
 */
proto.transporter_driver.ExportTransporterDriversRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_filter_request_pb.Request;
      reader.readMessage(value,protobuf_filter_request_pb.Request.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transporter_driver.ExportTransporterDriversRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transporter_driver.ExportTransporterDriversRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transporter_driver.ExportTransporterDriversRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transporter_driver.ExportTransporterDriversRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_filter_request_pb.Request.serializeBinaryToWriter
    );
  }
};


/**
 * optional filter.Request filter = 1;
 * @return {?proto.filter.Request}
 */
proto.transporter_driver.ExportTransporterDriversRequest.prototype.getFilter = function() {
  return /** @type{?proto.filter.Request} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_request_pb.Request, 1));
};


/**
 * @param {?proto.filter.Request|undefined} value
 * @return {!proto.transporter_driver.ExportTransporterDriversRequest} returns this
*/
proto.transporter_driver.ExportTransporterDriversRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.transporter_driver.ExportTransporterDriversRequest} returns this
 */
proto.transporter_driver.ExportTransporterDriversRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.transporter_driver.ExportTransporterDriversRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transporter_driver.ExportTransporterDriversResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.transporter_driver.ExportTransporterDriversResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transporter_driver.ExportTransporterDriversResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transporter_driver.ExportTransporterDriversResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_export: (f = msg.getExport()) && protobuf_filter_export_pb.ExportResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transporter_driver.ExportTransporterDriversResponse}
 */
proto.transporter_driver.ExportTransporterDriversResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transporter_driver.ExportTransporterDriversResponse;
  return proto.transporter_driver.ExportTransporterDriversResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transporter_driver.ExportTransporterDriversResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transporter_driver.ExportTransporterDriversResponse}
 */
proto.transporter_driver.ExportTransporterDriversResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_filter_export_pb.ExportResponse;
      reader.readMessage(value,protobuf_filter_export_pb.ExportResponse.deserializeBinaryFromReader);
      msg.setExport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transporter_driver.ExportTransporterDriversResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transporter_driver.ExportTransporterDriversResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transporter_driver.ExportTransporterDriversResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transporter_driver.ExportTransporterDriversResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExport();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_filter_export_pb.ExportResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional filter.ExportResponse export = 1;
 * @return {?proto.filter.ExportResponse}
 */
proto.transporter_driver.ExportTransporterDriversResponse.prototype.getExport = function() {
  return /** @type{?proto.filter.ExportResponse} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_export_pb.ExportResponse, 1));
};


/**
 * @param {?proto.filter.ExportResponse|undefined} value
 * @return {!proto.transporter_driver.ExportTransporterDriversResponse} returns this
*/
proto.transporter_driver.ExportTransporterDriversResponse.prototype.setExport = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.transporter_driver.ExportTransporterDriversResponse} returns this
 */
proto.transporter_driver.ExportTransporterDriversResponse.prototype.clearExport = function() {
  return this.setExport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.transporter_driver.ExportTransporterDriversResponse.prototype.hasExport = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.transporter_driver);
