/**
 * @fileoverview gRPC-Web generated client stub for transporter_driver
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: protobuf/transporter_driver/transporter_driver.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as protobuf_model_merged_pb from '../../protobuf/model/merged_pb';
import * as protobuf_transporter_driver_transporter_driver_pb from '../../protobuf/transporter_driver/transporter_driver_pb';


export class TransporterDriverServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreate = new grpcWeb.MethodDescriptor(
    '/transporter_driver.TransporterDriverService/Create',
    grpcWeb.MethodType.UNARY,
    protobuf_transporter_driver_transporter_driver_pb.CreateTransporterDriverRequest,
    protobuf_model_merged_pb.TransporterDriverResponse,
    (request: protobuf_transporter_driver_transporter_driver_pb.CreateTransporterDriverRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.TransporterDriverResponse.deserializeBinary
  );

  create(
    request: protobuf_transporter_driver_transporter_driver_pb.CreateTransporterDriverRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.TransporterDriverResponse>;

  create(
    request: protobuf_transporter_driver_transporter_driver_pb.CreateTransporterDriverRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.TransporterDriverResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.TransporterDriverResponse>;

  create(
    request: protobuf_transporter_driver_transporter_driver_pb.CreateTransporterDriverRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.TransporterDriverResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/transporter_driver.TransporterDriverService/Create',
        request,
        metadata || {},
        this.methodDescriptorCreate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/transporter_driver.TransporterDriverService/Create',
    request,
    metadata || {},
    this.methodDescriptorCreate);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/transporter_driver.TransporterDriverService/Update',
    grpcWeb.MethodType.UNARY,
    protobuf_transporter_driver_transporter_driver_pb.UpdateTransporterDriverRequest,
    protobuf_model_merged_pb.TransporterDriverResponse,
    (request: protobuf_transporter_driver_transporter_driver_pb.UpdateTransporterDriverRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.TransporterDriverResponse.deserializeBinary
  );

  update(
    request: protobuf_transporter_driver_transporter_driver_pb.UpdateTransporterDriverRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.TransporterDriverResponse>;

  update(
    request: protobuf_transporter_driver_transporter_driver_pb.UpdateTransporterDriverRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.TransporterDriverResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.TransporterDriverResponse>;

  update(
    request: protobuf_transporter_driver_transporter_driver_pb.UpdateTransporterDriverRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.TransporterDriverResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/transporter_driver.TransporterDriverService/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/transporter_driver.TransporterDriverService/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorGet = new grpcWeb.MethodDescriptor(
    '/transporter_driver.TransporterDriverService/Get',
    grpcWeb.MethodType.UNARY,
    protobuf_transporter_driver_transporter_driver_pb.GetTransporterDriversRequest,
    protobuf_transporter_driver_transporter_driver_pb.GetTransporterDriversResponse,
    (request: protobuf_transporter_driver_transporter_driver_pb.GetTransporterDriversRequest) => {
      return request.serializeBinary();
    },
    protobuf_transporter_driver_transporter_driver_pb.GetTransporterDriversResponse.deserializeBinary
  );

  get(
    request: protobuf_transporter_driver_transporter_driver_pb.GetTransporterDriversRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_transporter_driver_transporter_driver_pb.GetTransporterDriversResponse>;

  get(
    request: protobuf_transporter_driver_transporter_driver_pb.GetTransporterDriversRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_transporter_driver_transporter_driver_pb.GetTransporterDriversResponse) => void): grpcWeb.ClientReadableStream<protobuf_transporter_driver_transporter_driver_pb.GetTransporterDriversResponse>;

  get(
    request: protobuf_transporter_driver_transporter_driver_pb.GetTransporterDriversRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_transporter_driver_transporter_driver_pb.GetTransporterDriversResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/transporter_driver.TransporterDriverService/Get',
        request,
        metadata || {},
        this.methodDescriptorGet,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/transporter_driver.TransporterDriverService/Get',
    request,
    metadata || {},
    this.methodDescriptorGet);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/transporter_driver.TransporterDriverService/Delete',
    grpcWeb.MethodType.UNARY,
    protobuf_transporter_driver_transporter_driver_pb.DeleteTransporterDriversRequest,
    protobuf_transporter_driver_transporter_driver_pb.DeleteTransporterDriversResponse,
    (request: protobuf_transporter_driver_transporter_driver_pb.DeleteTransporterDriversRequest) => {
      return request.serializeBinary();
    },
    protobuf_transporter_driver_transporter_driver_pb.DeleteTransporterDriversResponse.deserializeBinary
  );

  delete(
    request: protobuf_transporter_driver_transporter_driver_pb.DeleteTransporterDriversRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_transporter_driver_transporter_driver_pb.DeleteTransporterDriversResponse>;

  delete(
    request: protobuf_transporter_driver_transporter_driver_pb.DeleteTransporterDriversRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_transporter_driver_transporter_driver_pb.DeleteTransporterDriversResponse) => void): grpcWeb.ClientReadableStream<protobuf_transporter_driver_transporter_driver_pb.DeleteTransporterDriversResponse>;

  delete(
    request: protobuf_transporter_driver_transporter_driver_pb.DeleteTransporterDriversRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_transporter_driver_transporter_driver_pb.DeleteTransporterDriversResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/transporter_driver.TransporterDriverService/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/transporter_driver.TransporterDriverService/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorList = new grpcWeb.MethodDescriptor(
    '/transporter_driver.TransporterDriverService/List',
    grpcWeb.MethodType.UNARY,
    protobuf_transporter_driver_transporter_driver_pb.ListTransporterDriversRequest,
    protobuf_transporter_driver_transporter_driver_pb.ListTransporterDriversResponse,
    (request: protobuf_transporter_driver_transporter_driver_pb.ListTransporterDriversRequest) => {
      return request.serializeBinary();
    },
    protobuf_transporter_driver_transporter_driver_pb.ListTransporterDriversResponse.deserializeBinary
  );

  list(
    request: protobuf_transporter_driver_transporter_driver_pb.ListTransporterDriversRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_transporter_driver_transporter_driver_pb.ListTransporterDriversResponse>;

  list(
    request: protobuf_transporter_driver_transporter_driver_pb.ListTransporterDriversRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_transporter_driver_transporter_driver_pb.ListTransporterDriversResponse) => void): grpcWeb.ClientReadableStream<protobuf_transporter_driver_transporter_driver_pb.ListTransporterDriversResponse>;

  list(
    request: protobuf_transporter_driver_transporter_driver_pb.ListTransporterDriversRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_transporter_driver_transporter_driver_pb.ListTransporterDriversResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/transporter_driver.TransporterDriverService/List',
        request,
        metadata || {},
        this.methodDescriptorList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/transporter_driver.TransporterDriverService/List',
    request,
    metadata || {},
    this.methodDescriptorList);
  }

  methodDescriptorExport = new grpcWeb.MethodDescriptor(
    '/transporter_driver.TransporterDriverService/Export',
    grpcWeb.MethodType.UNARY,
    protobuf_transporter_driver_transporter_driver_pb.ExportTransporterDriversRequest,
    protobuf_transporter_driver_transporter_driver_pb.ExportTransporterDriversResponse,
    (request: protobuf_transporter_driver_transporter_driver_pb.ExportTransporterDriversRequest) => {
      return request.serializeBinary();
    },
    protobuf_transporter_driver_transporter_driver_pb.ExportTransporterDriversResponse.deserializeBinary
  );

  export(
    request: protobuf_transporter_driver_transporter_driver_pb.ExportTransporterDriversRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_transporter_driver_transporter_driver_pb.ExportTransporterDriversResponse>;

  export(
    request: protobuf_transporter_driver_transporter_driver_pb.ExportTransporterDriversRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_transporter_driver_transporter_driver_pb.ExportTransporterDriversResponse) => void): grpcWeb.ClientReadableStream<protobuf_transporter_driver_transporter_driver_pb.ExportTransporterDriversResponse>;

  export(
    request: protobuf_transporter_driver_transporter_driver_pb.ExportTransporterDriversRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_transporter_driver_transporter_driver_pb.ExportTransporterDriversResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/transporter_driver.TransporterDriverService/Export',
        request,
        metadata || {},
        this.methodDescriptorExport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/transporter_driver.TransporterDriverService/Export',
    request,
    metadata || {},
    this.methodDescriptorExport);
  }

}

